<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :value="(e.parent || { parent: {} }).parent.name"
          label="Saison"
          disabled
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="(e.parent || {}).name"
          label="Liga"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="e.startDate | dateformat"
          label="Beginn"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="halle(e)"
          label="Halle"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="modus(e)"
          label="Begegnungsmodus"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="status(e)"
          label="Begegnungsstatus"
          disabled
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'

export default {
  name: 'wettkampfdaten',

  setup (props, context) {
    return {
      ...useCalc(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
